<script setup lang="ts">
const emit = defineEmits(["accepted"]);

const consentDialogAccept = ref<HTMLElement>();

onMounted(() => {
  if (consentDialogAccept.value) consentDialogAccept.value.focus();
});
</script>

<template>
  <div
    class="privacy-footer"
    role="alertdialog"
    aria-labelledby="consentTitle"
    aria-describedby="consentDescription"
  >
    <div class="message">
      <h3 id="consentTitle" style="font-size: 1.6rem">Cookies</h3>
      <p id="consentDescription">
        This website uses cookies to give you the best browsing experience
        possible. If you continue to use this website without changing your
        browser's cookie settings you agree to this use of cookies. For more
        information visit
        <a
          href="https://www.jamestownlp.com/privacy-policy-and-disclaimers"
          target="_blank"
          aria-label="Read full Jamestown LLC privacy policy"
          >https://www.jamestownlp.com/privacy-policy-and-disclaimers</a
        >
      </p>
    </div>
    <div class="actions">
      <button
        ref="consentDialogAccept"
        class="accept"
        aria-label="Accept all cookies"
        @click="emit('accepted')"
      >
        Accept
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/scss/_vars";
.privacy-footer {
  position: fixed;
  z-index: 50000;
  bottom: 0;
  left: 0;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  color: #aaa;
  background: #222222;
  padding: 1rem 14rem;
  opacity: 0;
  animation: transitionIn 500ms ease-in 5000ms 1 normal forwards;

  @media screen and (max-width: 600px) {
    padding: 1rem 6rem;
  }

  @media (max-width: $media-query-mobile) {
    flex-direction: column;
  }

  .message {
    font-size: 1.2rem;
    text-align: left;

    p {
      line-height: 1.5;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 15px;

    @media (max-width: $media-query-mobile) {
      justify-content: center;
    }

    button,
    a {
      cursor: pointer;
      border-radius: 22px;
      border: 1px #ffffff solid;
      color: #ffffff;
      height: 40px;

      &.accept {
        background-color: #ffffff;
        font-weight: 700;
        color: #222222;
        padding-right: 3rem;
        padding-left: 3rem;
      }
    }
  }

  a {
    padding-top: 12px;
    text-decoration: none;
    color: white !important;
  }

  @keyframes transitionIn {
    0% {
      opacity: 0;
      transform: translateY(60px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
